.swipe {
  position: absolute;
}

.tinder-p {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.tinder {
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
}
