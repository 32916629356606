.App {
  text-align: center;
  /* font-family: 12px 'Roboto', Arial, Helvetica, sans-serif; */
  background-color: black;
}
.font-default {
  /* font-family: Helvetica; */
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  color: white;
}
.font-gray {
  /* font-family: Helvetica; */
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  color: #696974;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dizee-input {
  background: transparent;
  font-size: 12px;
}

.dizee-input:focus {
  outline: none;
}

.dizee-input::placeholder {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  color: #696974;
}

.input-gradient {
  background: linear-gradient(
        to right,
        #7953cd 20%,
        #00affa 30%,
        #0190cd 70%,
        #764ada 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}

.nayojs-calendar-header-button, .nayojs-calendar-navigators-button, .nayojs-calendar-day-number {
  color: white !important;
}

.nayojs-calendar-day-title {
  color: #4F4F4F !important;
}
.nayojs-calendar-container {
  padding: 0px !important;
}
.nayojs-calendar-day-number-selected, .nayojs-calendar-selector-list-item-active {
  background-color: black !important;
  color: red !important;
}