.color-picker {
    /* width: 100%; */
    text-align: center;
  }
  
  .hue-slider {
    -webkit-appearance: none;
    width: 300px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to right, red, yellow, lime, cyan, blue, magenta, red);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
  }
  
  .hue-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .hue-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .brightness-slider {
    -webkit-appearance: none;
     width: 300px;
    height: 5px;
    border-radius: 5px;
    background: linear-gradient(to right, white, blue);
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
    margin-top: 10px;
  }
  
  .brightness-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .brightness-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .color-display {
    border: 1px solid black;
  }
  
  .grayscale-slider {
    -webkit-appearance: none;
    width: 300px;
    height: 5px;
    border-radius: 5px;
    background: white;
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
  }

  .grayscale-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  .grayscale-slide::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }